
import React, { Component } from "react";
import Form from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";
import IFrame from './iframe';
import FloatMenu from './float-menu';
import HTMLEditor from './htmleditor';
import FileWidgetExtended from './FileWidgetExtended';
import ColorPicker from './color-picker';

import SEOField from './widgets/SEOField';
import TitlesField from './widgets/TitlesField';
import ConfigField from './widgets/ConfigField';
import GooglePlaceField from './widgets/GooglePlaceField';

var IFrameCommunicator = require('../libs/iframe-communication');
var uiSchema = require('./ui-schemas/page-ui-schema.js');
var gridSchema = require('./ui-schemas/grid-schema.js');
var fieldTypes2Data = require('./ui-schemas/page-field-type-2-data.js');

import EditMethods from './edit-methods.js';
import SEOAdjuster from "./seo-adjuster";
var shallowCompare = require('react-addons-shallow-compare');

var moment = require('moment');


export default class PageEdit extends EditMethods {
    constructor(props) {
        super(props);
        global.checks = this.checks;
        this.seoAdjuster = new SEOAdjuster();
        this.additional_widgets = {
            file_extended: FileWidgetExtended,
            html_editor: HTMLEditor,
            colorpicker: ColorPicker
        }
        this.state = {
            initialized: false,
            page: null,
            schema: null,
            domain: null,
            start_date: moment().subtract(7, 'days').format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD")
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }
    validateUserInteractions() {
        this.props.client.read('/webpages/' + this.props.resource.resourceId + '/analytics/user-interactions/' + this.props.resource.subResourceId + '?start_date=' + this.state.start_date + '&end_date=' + this.state.end_date).then((interactions) => {
            if (interactions.status === 200) {
                this.interactions = interactions.data;
                this.forceUpdate(() => {
                    $('.chart').easyPieChart({
                        barColor: '#26B99A',
                        trackColor: '#fff',
                        scaleColor: false,
                        lineWidth: 10,
                        trackWidth: 12,
                        animate: false,
                        size: 60,
                        lineCap: 'butt',
                        onStep: function (from, to, percent) {
                            $(this.el).find('.percent').text(Math.round(percent));
                        }
                    });
                });
            }
        });
    }
    validate(props) {
        global.groupId = this.props.resource.resourceId;
        let group_path = '/webpages/' + this.props.resource.resourceId;
        let pages = null;
        this.props.client.read(group_path + '/pages?skip=0&limit=100&fields=sub_path').then((resp) => {
            pages = resp.data;
            return this.props.client.read('/schema/page');
        }).then((schema) => {
            schema.data.properties.sections.items.properties._id = { "type": "string" };
            this.schema = schema.data;
            this.schema.properties.sidebar.definitions = this.schema.definitions;
            console.log('page init 1', schema.data, schema.status);
            return this.props.client.read('/webpages/' + this.props.resource.resourceId + '/domains')
        }).then((domains) => {
            this.domain = domains.data.main_domain;
            this.protocol = domains.data.enable_ssl === true ? 'https' : 'http';
            global.protocol = this.protocol; // for file extended widget
            global.main_domain = this.domain; // for file extended widget

            console.log('page init 2', domains.data, domains.status);
            return this.props.client.read('/webpages/' + this.props.resource.resourceId + '/pages/' + this.props.resource.subResourceId);
        }).then((page) => {
            this.storage = page.data;
            this.storage.seo.sub_path = this.storage.sub_path;
            this.temporary_sub_path = this.storage.sub_path + (this.storage.status === 'draft' ? '--draft' : '');
            pages = pages.filter(p => p._id !== page.data._id);
            if (pages.length > 0) {
                var sub_path_regex = '.*(?<!';
                sub_path_regex += pages.map(p => '^' + p.sub_path).join('|');
                sub_path_regex += ')$';
                this.schema.properties.sub_path.pattern = sub_path_regex;
            }
            console.log(sub_path_regex, this.schema.properties.sub_path);
            emitter.emit('DASHBOARD_TITLE_UPDATE', {
                domain: this.domain,
                resource: "page",
                title: this.storage.seo.head_title
            });

            this.setState({
                initialized: true,
                widget_visibilities: {}

            }, () => {
                this.validateUserInteractions();
                this.process_checks();
            });

        });
    }
    componentDidMount() {
        this.validate(this.props);
    }
    componentWillReceiveProps(newProps) {

        this.validate(newProps);
    }
    componentDidUpdate(prevProps, prevState) {

        var elementInViewport = (element, container) => {
            return $(element).offset().top > $(container).offset().top && $(element).offset().top < $(container).offset().top + $(container).height();
        }

        $('.page-form').unbind('scroll').scroll(function () {
            var first = null;

            $('.x_panel[data-id]').each(function (idx, el) {
                var is_visible_in_view = elementInViewport($(el), $('.page-form').get(0));
                if (first === null && is_visible_in_view) {
                    first = $(el);
                }
            });
            if (first !== null) {
                var id = first.attr('data-id');
                if (typeof (id) !== 'undefined' && id.trim().length > 0 && (!state.widget_active || state.widget_active.trim().length() === 0)) {
                    new IFrameCommunicator('.preview iframe').trigger({ action: 'scroll_to', target: id });
                }
            }
        });
    }
    handleSectionNew(idx, ev) {


        emitter.emit('SHOW_MODAL', {
            ok: (field_type) => {
                //identify section type
                if (typeof (field_type) === 'undefined') field_type = 'Slider';
                var field_data_type = fieldTypes2Data[field_type];
                var new_page_element = { field_type: field_type };

                if (field_data_type !== 'slider_data') {
                    new_page_element[field_data_type] = {};
                    new_page_element[field_data_type].alignment = 'left';
                } else {
                    new_page_element[field_data_type] = [];
                }

                //identify if first
                if (ev) {
                    this.storage.sections.splice(idx, 0, new_page_element);
                    this.lastNewIdx = idx;
                } else {
                    this.storage.sections.push(new_page_element);
                    this.lastNewIdx = 0;
                }
                //show save button
                this.forceUpdate();

            },
            cancel: () => {
            },
            state: {
                title: 'Neues Seitenelement',
                cancel_title: 'Abbrechen',
                ok_title: 'Ok',
                form: {
                    schema: {
                        title: "Inhaltselement auswählen",
                        type: "string",
                        required: true,
                        enum: Object.keys(fieldTypes2Data)
                    }
                }
            }
        });
    }
    save() {

        emitter.trigger('SHOW_LOADING');
        try {
            if (!this.storage.seo.head_description || this.storage.seo.head_description.trim().length === 0) {
                this.seoAdjuster.adjust(this.storage);
                this.forceUpdate();
            }
        } catch (e) {
            console.error(e);
        }


        var promises = [];
        //TODO: workaround before movement to seo fields
        if (this.storage.seo.sub_path) {
            this.storage.sub_path = this.storage.seo.sub_path;
        }
        var process = (key, value, parent) => {
            if (typeof (value) === 'string') {
                if (value.indexOf('data:image') !== -1 && value.indexOf('data:image') === 0) {
                    promises.push(new Promise((resolve, reject) => {
                        this.props.client.create('/webpages/' + this.storage.ref_targetgroup_id + '/media', { base64data: value }).then(function (response) {
                            parent[key] = response.data.url;
                            console.log('IMG', key, parent);
                            resolve();
                        })
                    }));
                } else if (value.indexOf('http') === 0) {
                    console.log('image wrong save', value);
                }
            }
        }
        this.traverse(this.storage, process);
        Promise.all(promises).then(() => {
            this.props.client.update('/webpages/' + this.storage.ref_targetgroup_id + '/pages/' + this.storage._id, this.storage).then((updated_page) => {
                this.storage = updated_page.data;
                this.storage.seo.sub_path = this.storage.sub_path;
                if (this.lastNewIdx && this.state.widget_visibilities['undefined'] === true && typeof (this.storage.sections[this.lastNewIdx]) !== 'undefined') {
                    this.handleVisibilityClick(this.storage.sections[this.lastNewIdx]._id);
                }
                this.temporary_sub_path = this.storage.sub_path + (this.storage.status === 'draft' ? '--draft' : '');

                if (this.proctectionChanged) {
                    delete this.proctectionChanged;
                } else {
                }
                this.forceUpdate(() => {
                    setTimeout(() => {
                        new IFrameCommunicator('.preview iframe').trigger({ action: 'reload_body', target: '.page-entry' });
                    }, 1000)
                });
                emitter.trigger('HIDE_LOADING');

            })
        })
    }
    menu_params() {

        return {
            preview: {
                title: 'Vorschau',
                href: (this.domain.enable_ssl === true ? 'https' : 'http') + '://' + this.domain + '/' + this.temporary_sub_path
            },
            cancel: {
                title: 'Zurück',
                action: () => {
                    history.back();
                }
            },
            ok: {
                title: 'Speichern',
                disabled: false,//!this.state.page_changed,
                action: () => {
                    this.save();
                }
            }
        }
    }
    webpage_url() {

        var webpage_url = this.protocol + '://' + this.domain + '/' + this.temporary_sub_path

        webpage_url = webpage_url;//+ '?notrack';
        console.log('webpage_url', webpage_url);
        return webpage_url;
    }
    fields() {

        var CustomTitleField = function (title, required) {
            if (title.id !== 'root__title') {
                var legend = required ? title.title + '*' : title;
                return <legend>{legend}</legend>;
            } else { return <span />; }
        };

        var fields = {
            SEOField: SEOField,
            TitleField: CustomTitleField,
            TitlesField: TitlesField,
            ConfigField: ConfigField,
            GooglePlaceField: GooglePlaceField
        };

        return fields;
    }
    section_form(section, idx) {

        var data_type = fieldTypes2Data[section.field_type];
        var section_ui_schema = uiSchema.sections.items[data_type];
        var section_schema = this.schema.properties.sections.items.properties[data_type];
        var section_form_data = {};
        if (data_type === 'slider_data') {
            section_ui_schema = {
                array: section_ui_schema
            }
            section_form_data.array = section[data_type];
            section_schema = {
                type: 'object',
                title: section_schema.title,
                properties: {
                    array: section_schema
                }
            }
        } else {
            if (gridSchema[data_type] && gridSchema[data_type].order) {
                section_ui_schema["ui:order"] = gridSchema[data_type].order;
            }
            section_schema.definitions = this.schema.definitions;
            section_form_data = typeof (section[data_type]) !== 'undefined' ? section[data_type] : {};
        }
        section_form_data.field_type = section.field_type;
        section_form_data.data_type = data_type;
        section_form_data._id = section._id;
        var section_visibility = this.state.widget_visibilities[section._id];

        var section_display = section_visibility ? { display: 'block' } : { display: 'none' };
        var section_display_chevron = section_visibility ? 'fa fa-chevron-up' : 'fa fa-chevron-down';


        var section_popularity_rate = 0;
        var section_bounce_rate = 0;
        var anchor = data_type === 'slider_data' ? 'Slider' : section_form_data.anchor;


        var popularity_rate = 0;
        var section_popularity_rate = 0;
        var section_bounce_rate = 0;
        if (this.interactions) {
            var popularity_rate = this.interactions.popularity_rate[section._id];
            if (popularity_rate) {
                section_popularity_rate = parseInt(this.interactions.popularity_rate[section._id] * 100);
            }
            var bounce_rate = this.interactions.bounce_rate[section._id];
            if (bounce_rate) {
                section_bounce_rate = parseInt(this.interactions.bounce_rate[section._id] * 100);
            }

        }


        var section_form_inner = (
            <Form
                widgets={this.additional_widgets}
                uiSchema={section_ui_schema}
                schema={section_schema}
                fields={this.fields()}
                formData={section_form_data}
                onChange={(ev) => this.handleSectionChange(idx, ev)}
                validator={validator}
            >
                <div></div>
            </Form>
        );
        console.log(section_form_data);
        var section_title = section_form_data && section_form_data.titles && section_form_data.titles.title && section_form_data.titles.title.length > 0 ? section_form_data.titles.title : '';
        var section_title_snippet = '';
        if (typeof (section_title) !== 'undefined' && section_title.trim().length > 0) {
            section_title_snippet = ' / ' + section_title;
        }
        var open_statistics = () => {
            if (!this.is_section_visible(section_form_data)) {
                return (
                    <div>
                        <div className="col-md-6 text-center">
                            <div>Prominenz</div>
                            <span className="chart chart-small" data-percent={section_popularity_rate}><span className="percent percent-small">{section_popularity_rate}</span></span>
                        </div>
                        <div className="col-md-6 text-center">
                            <div>Absprungrate</div>
                            <span className="chart chart-small" data-percent={section_bounce_rate}><span className="percent percent-small">{section_bounce_rate}</span></span>
                        </div>
                    </div>
                );
            } else return '';
        }
        return (
            <div key={section_form_data._id} className={"x_panel " + data_type.replace('_data', '')} data-id={section_form_data._id}>
                <div className="x_title">
                    <h2>{'#' + idx} {section_schema.title} {section_title_snippet}</h2>
                    <ul className="nav navbar-right panel_toolbox">
                        <li><a onClick={(ev) => this.handleSectionMoveUp(idx, ev)}><i className="fa fa-arrow-up"></i></a></li>
                        <li><a onClick={(ev) => this.handleSectionMoveDown(idx, ev)}><i className="fa fa-arrow-down"></i></a></li>
                        <li><a onClick={(ev) => this.handleSectionNew(idx, ev)}><i className="fa fa-plus"></i></a></li>
                        <li><a onClick={(ev) => this.handleSectionDelete(idx, ev)}><i className="fa fa-minus"></i></a></li>
                        <li><a onClick={(ev) => this.handleSectionCopy(idx, ev)}><i className="fa fa-files-o"></i></a></li>
                        <li><a onClick={(ev) => this.handleSectionPaste(idx, ev)}><i className="fa fa-clipboard"></i></a></li>
                        <li><a onClick={(ev) => this.handleVisibilityClick(section._id)}><i className={section_display_chevron}></i></a></li>
                    </ul>
                    <div className="clearfix"></div>
                </div>
                <div className="x_content">
                    <div className="row">
                        {open_statistics()}
                        <div className="col-md-12">
                            <div id={section._id} key={idx} className="section-edit-wrapper" style={section_display}>
                                {section_form_inner}
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
    section_forms() {

        var sections = [];
        if (this.storage.sections.length > 0) {
            this.storage.sections.map((section, idx) => {
                var section_form = this.section_form(section, idx);
                sections.push(section_form);
            })
        } else {
            sections.push(<button className="btn btn-default" onClick={() => this.handleSectionNew()}>Neues Seitenelement</button>);
        }
        return sections;
    }
    is_section_visible(section_data) {
        var visibility = this.state.widget_visibilities[section_data._id];
        return visibility === true;
    }
    render() {


        if (this.state.initialized) {
            var seo_visibility = this.state.widget_visibilities['seo'];
            var seo_display = seo_visibility ? { display: 'block' } : { display: 'none' };
            var seo_display_chevron = seo_visibility ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

            var sidebar_visibility = this.state.widget_visibilities['sidebar'];
            var sidebar_display = sidebar_visibility ? { display: 'block' } : { display: 'none' };
            var sidebar_display_chevron = sidebar_visibility ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

            var custom_visibility = this.state.widget_visibilities['custom'];
            var custom_display = custom_visibility ? { display: 'block' } : { display: 'none' };
            var custom_display_chevron = custom_visibility ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

            var webpage_url = this.webpage_url();
            console.log("seo", this.schema.properties.seo);

            return (
                <div className="row" style={{ paddingBottom: '40px' }}>
                    <div className="col-md-6 page-form">
                        {this.get_warnings()}
                        {this.get_dangers()}
                        <div className="x_panel page-seo seo">
                            <div className="x_title">
                                <h2>{this.schema.properties.seo.title}</h2>
                                <ul className="nav navbar-right panel_toolbox">
                                    <li><a onClick={() => this.handleVisibilityClick('seo')}><i className={seo_display_chevron}></i></a></li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="x_content" style={seo_display}>
                                <Form
                                    transformErrors={(a, b) => {
                                        console.log('transform formData', a, b);
                                    }}
                                    validate={(formData, errors) => {
                                        console.log('formData', formData, errors);


                                        return errors;
                                    }}
                                    widgets={this.additional_widgets}
                                    uiSchema={uiSchema.seo}
                                    schema={this.schema.properties.seo}
                                    formData={this.storage.seo}
                                    fields={this.fields()}
                                    onChange={(ev) => this.handleSEOChange(ev.formData)}
                                    onError={
                                        (a, b) => {
                                            console.log('onError formData', a, b);
                                        }
                                    }
                                    validator={validator}

                                >

                                    <div></div>
                                </Form>
                                <Form
                                    uiSchema={{}}
                                    schema={this.schema.properties.protected}
                                    formData={this.storage.protected}
                                    onChange={(ev) => this.handleProtected(ev)}
                                    validator={validator}>
                                    <div></div>
                                </Form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="x_panel page-sidebar sidebar">
                            <div className="x_title">
                                <h2>{this.schema.properties.sidebar.title}</h2>
                                <ul className="nav navbar-right panel_toolbox">
                                    <li><a onClick={() => this.handleVisibilityClick('sidebar')}><i className={sidebar_display_chevron}></i></a></li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                            <div className="x_content" style={sidebar_display}>
                                <Form
                                    widgets={this.additional_widgets}
                                    uiSchema={uiSchema.sidebar}
                                    schema={this.schema.properties.sidebar}
                                    formData={this.storage.sidebar}
                                    fields={this.fields()}
                                    onChange={(ev) => this.handleSidebarChange(ev)}
                                    validator={validator}>
                                    <div></div>
                                </Form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="x_panel page-status status">
                            <Form
                                schema={this.schema.properties.status}
                                formData={this.storage.status}
                                fields={this.fields()}
                                onChange={(ev) => this.storage.status = ev.formData}
                                validator={validator}>
                                <div></div>
                            </Form>
                        </div>
                        <div className="x_panel page-custom custom">
                            <div className="x_title">
                                <h2>{this.schema.properties.custom.title}</h2>
                                <ul className="nav navbar-right panel_toolbox">
                                    <li><a onClick={() => this.handleVisibilityClick('custom')}><i className={custom_display_chevron}></i></a></li>
                                </ul>
                                <div className="clearfix"></div>
                            </div>

                            <div className="x_content" style={custom_display}>
                                <Form
                                    widgets={this.additional_widgets}
                                    uiSchema={uiSchema.custom}
                                    schema={this.schema.properties.custom}
                                    formData={this.storage.custom}
                                    fields={this.fields()}
                                    onChange={(ev) => this.handleCustomChange(ev)}
                                    validator={validator}>
                                    <div></div>
                                </Form>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="x_panel page-user-stats user-stats">
                            <div className="form-group">
                                <label>Zeitraum Nutzerverhalten</label>
                                <form className="form-inline">
                                    <div className="form-group" style={{ textAlign: 'left', width: '32%' }}>
                                        <input type="date" style={{ width: '100%' }} className="form-control" placeholder="" onChange={(e) => this.setState({ start_date: e.target.value })} value={this.state.start_date}></input>
                                    </div>
                                    <div className="form-group" style={{ textAlign: 'left', width: '32%' }}>
                                        <input type="date" style={{ width: '100%' }} className="form-control" placeholder="" onChange={(e) => this.setState({ end_date: e.target.value })} value={this.state.end_date}></input>
                                    </div>
                                    <div className="form-group" style={{ textAlign: 'left', width: '32%' }}>
                                        <button style={{ width: '100%' }} className="btn btn-default" type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.validateUserInteractions();
                                            }}>Aktualisieren</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="page-sections sections">
                            {this.section_forms()}
                        </div>
                    </div>
                    <div className="col-md-6 page-preview preview">
                        <IFrame url={webpage_url} />
                    </div>
                    <FloatMenu params={this.menu_params()} />
                </div>
            )
        } else {
            return <div />;
        }
    }
}