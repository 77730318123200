import List from './list';
import Tile from './tile';
import Card from './card';
import FloatMenu from './float-menu';
import Analytics from './analytics';
var AnalyticsHelper = require('../libs/analytics-helper.js');
var moment = require('moment');
import React, { Component } from "react";

import quickCreateSchemas from './ui-schemas/quick-create-schemas';


export default class GroupDashboard extends Component {
    constructor(props) {
        super(props);
        this.analytics = new AnalyticsHelper();
        this.state = {
            initialized: false,
            design: null,
            pages: null,
            blogs: null,
            domains: null,
            google_adwords: null,
            progress: null,
            analytics: null
        };
    }
    validate(props) {
        var self = this;
        var channel_id = props.resource.resourceId;
        global.selected_channel_id = channel_id; // for form widgets
        emitter.emit('SHOW_LOADING');

        var channel_requests = [
            props.client.read('/webpages/' + channel_id + '/design'),
            //props.client.read('/webpages/' + channel_id + '/page'),
            //props.client.read('/webpages/' + channel_id + '/blog'),
            props.client.read('/webpages/' + channel_id + '/contacts?sort_field=created&sort_order=desc'),
            props.client.read('/webpages/' + channel_id + '/domains'),
            props.client.read('/webpages/' + channel_id + '/integration'),
            //props.client.read('/webpages/' + channel_id + '/progresses'),
            self.analytics.request(self.props.client, channel_id, 'range', 2, [
                { key: 'visits', main: 'visits-summary', sub: 'visits' },
                { key: 'visits_length', main: 'visits-summary', sub: 'sum-visits-length' },
                { key: 'visits_unique', main: 'visits-summary', sub: 'unique-visitors' }
            ], moment().toDate(), moment().subtract(1, 'weeks').toDate()),
            //props.client.read('/webpages/' + channel_id + '/media')
        ];
        Promise.all(channel_requests).then(function (responses) {
            emitter.emit('HIDE_LOADING');
            console.log('requests', responses);
            self.setState({
                initialized: true,
                design: responses[0].data,
                //pages: responses[1].data,
                //blogs: responses[2].data,
                contacts: responses[1].data,
                domains: responses[2].data,
                google_adwords: responses[3].data,
                //progresses: responses[6].status !== 200 ? [] : responses[6].data,
                analytics: responses[4],
                //media: responses[8].data,
                resource: props.resource,
                group_id: channel_id
            })
            console.log('after set state');
        })
    }
    componentDidMount() {
        var self = this;
        self.validate(self.props);
    }
    componentWillReceiveProps(newProps) {
        var self = this;

        self.validate(newProps);
    }
    componentDidUpdate(prevProps, prevState) {
        var self = this;
    }
    componentWillUpdate(prevProps, prevState) {
        var self = this;
    }

    onFullUpdate(ev) {
        var self = this;
        ev.preventDefault();
        emitter.emit('SHOW_LOADING');
        self.props.client.update('/webpages/' + self.props.resource.resourceId + '/config').then(function () {
            self.validate(self.props);
            emitter.emit('HIDE_LOADING');
        })
    }
    handleNew(new_target) {
        var self = this;
        var data = {
            title: 'Seite löschen',
            body_title: '',
            body_content: '',
            cancel_title: 'Abbrechen',
            ok_title: 'Ok',
            form: {

            }
        };
        let schema = quickCreateSchemas[new_target];
        data.title = schema.title;
        data.form = {schema: schema.schema, uiSchema: schema.uiSchema}; //benötigen beides

        emitter.emit('SHOW_MODAL', {
            ok: function (formData) {
                var new_entity = {};
                if (new_target === 'pages' || new_target === 'posts') {
                    new_entity = {
                        seo: {
                            head_title: formData
                        },
                        landingpage: false

                    }
                } else if (new_target === 'media') {
                    new_entity = { base64data: formData };
                } else {
                    new_entity = formData;
                }
                emitter.emit('SHOW_LOADING');
                self.props.client.create('/webpages/' + self.state.resource.resourceId + '/' + new_target, new_entity).then(function () {
                    self.validate(self.props);
                });
            },
            cancel: function () {
            },
            state: data
        });
    }
    render() {
        var self = this;

        if (self.state.initialized) {
            var channel_group_id = self.state.resource.resourceId;
            global.main_domain = self.state.domains.main_domain;
            global.protocol = self.state.domains.enable_ssl ? 'https' : 'http';
            var uiSchema = {
            };
            var page_types = {
                'seo.head_title': {
                    title: 'Title',
                    type: 'string'
                }
            };
            var blog_types = {
                'seo.head_title': {
                    title: 'Title',
                    type: 'string'
                },
                'post_date': {
                    title: 'Datum',
                    type: 'string'
                },
            };
            var contact_types = {
                email: {
                    title: 'Email',
                    type: 'string'
                },
                status: {
                    title: 'Status',
                    type: 'string'
                }
            };
            var media_types = {
                name: {
                    title: 'Dateiname',
                    type: 'string'
                },
                tags: {
                    title: 'Tags',
                    type: 'array'
                }
            };
            var progresses_types = {
                date: {
                    title: 'Datum',
                    type: 'date'
                },
                effort: {
                    title: 'Aufwand (h)',
                    type: 'string'
                },
                title: {
                    title: 'Titel',
                    type: 'string'
                },
                description: {
                    title: 'Beschreibung',
                    type: 'string'
                }
            };
            var page_actions = [
                {
                    type: 'button',
                    //title: 'Bearbeiten',
                    icon: 'edit',
                    action: function (ev) {
                        var page_id = ev.currentTarget.dataset.id;
                        location.hash = '#webpages/' + self.state.resource.resourceId + '/pages/' + page_id;
                    }
                },
                {
                    type: 'button',
                    //title: 'Löschen',
                    icon: 'remove',
                    action: function (ev) {
                        var page_id = ev.currentTarget.dataset.id;


                        self.props.client.read('/webpages/' + self.state.resource.resourceId + '/pages/' + page_id).then(function (page_res) {
                            var page = page_res.data;
                            emitter.emit('SHOW_MODAL', {
                                ok: function () {
                                    emitter.emit('SHOW_LOADING');
                                    self.props.client.remove('/webpages/' + self.state.resource.resourceId + '/pages/' + page_id).then(function () {
                                        self.validate(self.props);
                                    })
                                },
                                cancel: function () {
                                },
                                state: {
                                    title: 'Seite löschen',
                                    body_title: '"' + page.seo.head_title + '"',
                                    body_content: 'Sind sie sicher, dass Sie die Seite löschen möchten?',
                                    cancel_title: 'Abbrechen',
                                    ok_title: 'Löschen'
                                }
                            });
                        });
                    }
                },
                {
                    type: 'button',
                    //title: 'Kopieren',
                    icon: 'copy',
                    action: function (ev) {
                        var page_id = ev.currentTarget.dataset.id;
                        emitter.emit('SHOW_MODAL', {
                            ok: function (formData) {
                                emitter.emit('SHOW_LOADING');
                                var new_page_name = formData;
                                self.props.client.read('/webpages/' + self.state.resource.resourceId + '/pages/' + page_id).then(function (page_res) {
                                    var page = page_res.data;
                                    delete page._id;
                                    delete page.ref_product_id;
                                    delete page.ref_targetgroup_id;
                                    delete page.__v;
                                    page.landingpage = false;
                                    page.seo.head_title = new_page_name;

                                    self.props.client.create('/webpages/' + self.state.resource.resourceId + '/pages', page).then(function () {
                                        self.validate(self.props);
                                        emitter.emit('HIDE_LOADING');
                                    })
                                });
                            },
                            cancel: function () {
                            },
                            state: {
                                title: 'Seite kopieren',
                                body_title: '',
                                body_content: '',
                                form: {
                                    schema: {
                                        title: "Neuer Seitenname",
                                        type: "string",
                                        required: true
                                    }
                                },
                                cancel_title: 'Abbrechen',
                                ok_title: 'Kopieren'
                            }
                        });
                    }
                },
                {
                    type: 'link',
                    //title: 'Anschauen',
                    icon: 'caret-square-o-right',
                    url: 'http://' + self.state.domains.main_domain + '/$(sub_path)'
                }
            ];
            var blog_actions = [
                {
                    type: 'button',
                    //title: 'Bearbeiten',
                    icon: 'edit',
                    action: function (ev) {
                        var blog_id = ev.currentTarget.dataset.id;
                        location.hash = '#webpages/' + self.state.resource.resourceId + '/posts/' + blog_id;
                    }
                },
                {
                    type: 'button',
                    //title: 'Löschen',
                    icon: 'remove',
                    action: function (ev) {
                        var blog_id = ev.currentTarget.dataset.id;

                        self.props.client.read('/webpages/' + self.state.resource.resourceId + '/posts/' + blog_id).then((res) => {
                            var blog = res.data;
                            emitter.emit('SHOW_MODAL', {
                                ok: function () {
                                    emitter.emit('SHOW_LOADING');
                                    self.props.client.remove('/webpages/' + self.state.resource.resourceId + '/posts/' + blog._id).then(() => {
                                        self.validate(self.props);
                                    })
                                },
                                cancel: function () {
                                },
                                state: {
                                    title: 'Blogartikel löschen',
                                    body_title: '"' + blog.seo.head_title + '"',
                                    body_content: 'Sind sie sicher, dass Sie den Blogartikel löschen möchten?',
                                    cancel_title: 'Abbrechen',
                                    ok_title: 'Löschen'
                                }
                            });
                        })
                    }

                },
                {
                    type: 'link',
                    //title: 'Anschauen',
                    icon: 'caret-square-o-right',
                    url: 'http://' + self.state.domains.main_domain + '/$(sub_path)'
                }
            ];
            var contact_actions = [
                {
                    type: 'button',
                    icon: 'edit',
                    //title: 'Bearbeiten',
                    action: function (ev) {
                        var contact_id = ev.currentTarget.dataset.id;
                        location.hash = '#webpages/' + self.state.resource.resourceId + '/contacts/' + contact_id;
                    }
                },
                {
                    type: 'button',
                    icon: 'remove',
                    //title: 'Löschen',
                    action: function (ev) {
                        var contact_id = ev.currentTarget.dataset.id;
                        var contact = _.find(self.state.contacts, function (contact) { return contact._id === contact_id; });
                        emitter.emit('SHOW_MODAL', {
                            ok: function () {
                                emitter.emit('SHOW_LOADING');
                                self.props.client.remove('/webpages/' + self.state.resource.resourceId + '/contacts/' + contact_id).then(function () {
                                    self.validate(self.props);
                                })
                            },
                            cancel: function () {
                            },
                            state: {
                                title: 'Kontakt löschen',
                                body_title: '"' + (typeof (contact.name) === 'undefined' || contact.name.trim().length === 0 ? contact.email : contact.name) + '"',
                                body_content: 'Sind sie sicher, dass Sie diesen Kontakt löschen möchten?',
                                cancel_title: 'Abbrechen',
                                ok_title: 'Löschen'
                            }
                        });
                    }
                }
            ];
            var media_actions = [
                {
                    type: 'button',
                    icon: 'edit',
                    //title: 'Bearbeiten',
                    action: function (ev) {
                        var file_name = ev.currentTarget.dataset.id;
                        self.props.client.read('/schema/media').then((res) => {
                            let schema = res.data;
                            self.props.client.read('/webpages/' + self.state.resource.resourceId + '/media/' + file_name).then((res) => {
                                let formData = res.data;
                                var data = {
                                    title: 'Bild bearbeiten',
                                    body_title: '',
                                    body_content: '',
                                    cancel_title: 'Abbrechen',
                                    ok_title: 'Ok',
                                    form: {
                                        schema,
                                        formData,
                                        uiSchema: {
                                            url: {
                                                "ui:readonly": true
                                            },
                                            description: {
                                                "ui:widget": "textarea"
                                            },
                                            base64data: {
                                                "ui:widget": "hidden"
                                            }
                                        }
                                    }
                                };

                                emitter.emit('SHOW_MODAL', {
                                    ok: function (formData) {
                                        console.log('media data save', formData);
                                        emitter.emit('SHOW_LOADING');
                                        self.props.client.update('/webpages/' + self.state.resource.resourceId + '/media/' + file_name, formData).then(function () {
                                            self.validate(self.props);
                                        })
                                    },
                                    cancel: function () {
                                    },
                                    state: data
                                });
                            });
                        })
                    }
                },
                {
                    type: 'link',
                    //title: 'Anschauen',
                    icon: 'image',
                    url: global.protocol + '://' + self.state.domains.main_domain + '$(url)'
                },
                {
                    type: 'button',
                    //title: 'Löschen',
                    icon: 'remove',
                    action: function (ev) {
                        var name = ev.currentTarget.dataset.id;

                        emitter.emit('SHOW_MODAL', {
                            ok: function () {
                                emitter.emit('SHOW_LOADING');
                                self.props.client.remove('/webpages/' + self.state.resource.resourceId + '/media/' + name).then(function () {
                                    self.validate(self.props);
                                })
                            },
                            cancel: function () {
                            },
                            state: {
                                title: 'Datei löschen',
                                body_title: '"' + name + '"',
                                body_content: 'Sind sie sicher, dass Sie diese Datei löschen möchten?',
                                cancel_title: 'Abbrechen',
                                ok_title: 'Löschen'
                            }
                        });
                    }
                }
            ];
            var progresses_actions = [
                /*{
                    type: 'button',
                    title: 'Bearbeiten',
                    action: function (ev) {
                        var progress_id = ev.currentTarget.dataset.id;
                        emitter.emit('SWITCH_CONTENT_VIEW', { id: 'progress-edit', name: 'progress-edit', params: { id: self.state.resource.resourceId, progress_id: progress_id } });
                    }
                }*/
            ];

            var menu_params = {
                cancel: {
                    title: 'Zurück',
                    action: function () {
                        location.hash = '#webpages'
                    }
                }
            }

            var analytics = {
                visits: {
                    current: self.state.analytics.visits[1].value,
                    before: self.state.analytics.visits[0].value
                },
                visits_length: {
                    current: self.state.analytics.visits_length[1].value,
                    before: self.state.analytics.visits_length[0].value
                },
                visits_unique: {
                    current: self.state.analytics.visits_unique[1].value,
                    before: self.state.analytics.visits_unique[0].value
                }
            }
            analytics.visits.grow = parseInt((analytics.visits.current / (analytics.visits.before !== 0 ? analytics.visits.before : 1)) * 100) - (analytics.visits.before !== 0 ? 100 : 0);
            analytics.visits_unique.grow = parseInt((analytics.visits_unique.current / (analytics.visits_unique.before !== 0 ? analytics.visits_unique.before : 1)) * 100) - (analytics.visits_unique.before !== 0 ? 100 : 0);
            analytics.visits_length.grow = parseInt((analytics.visits_length.current / (analytics.visits_length.before !== 0 ? analytics.visits_length.before : 1)) * 100) - (analytics.visits_length.before !== 0 ? 100 : 0);
            //<Tile cols="2" title="Eindeutige Besucher / 7 Tage" current={analytics.visits_unique.current} last={analytics.visits_unique.grow + ' %'} last_title="Zur letzten Woche"/>

            return (
                <div className="group-dashboard">
                    <div className="row tile_count">
                        <Tile cols="2" title="Besuche / 7 Tage" current={analytics && analytics.visits && analytics.visits.current ? analytics.visits.current : 0} last={analytics && analytics.visits && analytics.visits.grow ? analytics.visits.grow : 0 + ' %'} last_title="Zur letzten Woche" />
                        <Tile cols="2" title="Besuchsdauer / 7 Tage" current={(Array.isArray(analytics) ? analytics.visits_length.current / 1000 : 0).toFixed(2) + ' s'} last={analytics && analytics.visits_length && analytics.visits_length.grow ? analytics.visits_length.grow : 0 + ' %'} last_title="Zur letzten Woche" />
                        <Tile cols="2" title="Kontakte" current={self.state.contacts.length} last="~%" last_title="Zur letzten Woche" />
                        <div className="col-md-6">
                            <Analytics
                                steps={6}
                                date={new Date()}
                                graph_type="line"
                                type="month"
                                categories={[
                                    { title: 'Alle Besuche', color: '#1ABB9C', main: 'visits-summary', sub: 'visits', data_path: 'value' },
                                    { title: 'Eindeutige Besucher', color: '#2e6da4', main: 'visits-summary', sub: 'unique-visitors', data_path: 'value' }
                                ]}
                                group_id={self.state.resource.resourceId}
                                client={self.props.client}
                                height='110' />
                        </div>
                    </div>
                    <div className="row">
                        <Card id={"webpage-quick-actions"} title="Schnelle Aktionen" additionalClass="quick-actions-card" cols="3 3 6 12" height="300">
                            <div className="dashboard-widget-content">
                                <ul className="quick-list" style={{ width: '100%' }}>
                                    <li><i className="fa fa-external-link"></i><a href={'http://' + self.state.domains.main_domain + '?live&notrack'} target="_blank">Seite öffnen</a></li>
                                    {(global.is_super_admin || global.is_admin || global.is_pages) &&
                                        <li><i className="fa fa-file-text-o"></i><a href="#" data-target="page-edit" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNew('pages');
                                        }}>Neue Unterseite</a></li>
                                    }
                                    {(global.is_super_admin || global.is_admin || global.is_posts) &&
                                        <li><i className="fa fa-newspaper-o"></i><a href="#" data-target="blog-edit" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNew('posts')
                                        }}>Neuer Blogartikel</a></li>
                                    }
                                    {(global.is_super_admin || global.is_admin) &&
                                        <li><i className="fa fa-picture-o"></i><a href="#" data-target="media-edit" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNew('media')
                                        }}>Neue Datei</a></li>
                                    }
                                    {(global.is_super_admin || global.is_admin || global.is_contacts) &&
                                        <li><i className="fa fa-shopping-cart"></i><a href="#" data-target="contact-edit" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNew('contacts');
                                        }}>Neuer Kontakt</a></li>
                                    }
                                    {(global.is_super_admin || global.is_admin) &&
                                        <li><i className="fa fa-tasks"></i><a href="#" data-target="progress-edit" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNew('progresses')
                                        }}>Neuer Fortschritt</a></li>
                                    }

                                </ul>
                            </div>
                        </Card>
                        {(global.is_super_admin || global.is_admin) &&
                            <Card id={"webpage-configuration"} title="Einstellungen" additionalClass="configuration-card" cols="3 3 6 12" height="300">
                                <div className="dashboard-widget-content">
                                    <ul className="quick-list" style={{ width: '100%' }}>
                                        <li><i className="fa fa-cogs" data-target="base-configuration-edit" ></i><a href={'#webpages/' + channel_group_id + '/webpage'}>Basiseinstellung (Logo, Header, ...)</a></li>
                                        <li><i className="fa fa-picture-o" data-target="design-edit"></i><a href={'#webpages/' + channel_group_id + '/design'}>Layout (Farben, Schriften, ...)</a></li>
                                        <li><i className="fa fa-bars"></i><a href={'#webpages/' + channel_group_id + '/menu'} data-target="menu-edit">Menü</a></li>
                                        <li><i className="fa fa-globe"></i><a href={'#webpages/' + channel_group_id + '/domains'} data-target="domains-edit">Domains</a></li>
                                        <li><i className="fa fa-google"></i><a href={'#webpages/' + channel_group_id + '/integration'} data-target="integration-edit">Google</a></li>
                                        <li><i className="fa fa-comments-o"></i><a href="#" onClick={(e) => self.onFullUpdate(e)}>Vollständiges Update</a></li>
                                    </ul>
                                </div>
                            </Card>
                        }
                        {(global.is_super_admin || global.is_admin || global.is_contacts) &&
                            <Card id={"webpage-contacts"} title="Kontakte" additionalClass="contacts-card" cols="6 6 12 12" height="300">
                                <List client={this.props.client} id_type="_id" group_id={this.state.group_id} resource="contacts" types={contact_types} max={3} values={self.state.contacts} actions={contact_actions} />
                            </Card>
                        }
                        {(global.is_super_admin || global.is_admin || global.is_pages) &&
                            <Card id={"webpage-pages"} title="Webseiten" additionalClass="pages-card" cols="6 6 6 12" height="350">
                                <List client={this.props.client} id_type="_id" group_id={this.state.group_id} resource="pages" types={page_types} max={4} values={self.state.pages} actions={page_actions} />
                            </Card>
                        }
                        {(global.is_super_admin || global.is_admin || global.is_posts) &&
                            <Card id={"webpage-posts"} title="Blogartikel" additionalClass="posts-card" cols="6 6 6 12" height="350">
                                <List client={this.props.client} id_type="_id" group_id={this.state.group_id} resource="posts" types={blog_types} max={4} values={self.state.blogs} actions={blog_actions} sort={'post_date,-1'} />
                            </Card>
                        }
                        {(global.is_super_admin || global.is_admin || global.is_media) &&
                            <Card id={"webpage-media"} title="Dateien" additionalClass="media-card" cols="6 6 6 12" height="350">
                                <List client={this.props.client} id_type="name" group_id={this.state.group_id} resource="media" show_filter types={media_types} max={4} values={self.state.media} actions={media_actions} />
                            </Card>
                        }
                        {(global.is_super_admin || global.is_admin || global.is_progresses) &&
                            <Card id={"webpage-progresses"} title="Fortschritte" additionalClass="progresses-card" cols="6 6 6 12" height="350">
                                <List client={this.props.client} id_type="_id" group_id={this.state.group_id} resource="progresses" types={progresses_types} max={4} values={self.state.progresses} actions={progresses_actions} />
                            </Card>
                        }
                    </div>
                    <FloatMenu params={menu_params} />
                </div>
            )
        } else {
            return <div />;
        }

    }
}


